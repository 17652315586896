////////// VARIABLES \\\\\\\\\\
// global
$bg-image: '../assets/dg-tattoo-bg.JPG';
$bg-arrangement: no-repeat center center fixed;
$body-fonts: 'Roboto Condensed',
sans-serif;
$header-fonts: 'Playfair Display SC',
serif;
$nav-fonts: $header-fonts;
$useShadows: false;
// Theme colors
$theme-main: #1D5C63;
$theme-bg-bottom: #000;
$theme-bg-top: #1A3C40;
$theme-content-color: #EDE6DB;
$theme-header-color: #E6DDC4; //$theme-main;
$theme-content-subtext-color: rgba($theme-content-color, 0.7);
$theme-link-color: #417D7A;
$theme-link-active-color: lighten($theme-link-color, 10);
$theme-nav-color: $theme-header-color;
$theme-nav-active-color: #417D7A; //$theme-main;
$mobile-nav-hover-bg: rgba(255, 255, 255, 0.5);
$container-bg-color: rgba(lighten($theme-bg-top, 0.8), 0.6);
$section-head-bg-color: rgba(darken($theme-bg-top, 0.8), 0.6);
// colors
$bg-bottom: $theme-bg-bottom;
$bg-top: $theme-bg-top;
$loaderColor: $theme-content-color;
$menu-back: hsla(0, 10%, 2%, 0.85);
$placeholder-color: rgba($theme-header-color, 0.5);
$soft-bg: $container-bg-color;
$transparent: rgba(0, 0, 0, 0);
$contentHeading: $theme-header-color;
$wrapQuotes: rgba($theme-header-color, 0.2);
$main-text-color: $theme-content-color;
$form-labels: $theme-main;
$link-color: $theme-link-color;
$link-active-color: $theme-link-active-color;
$title-color: $theme-header-color;
$subtitle-color: $theme-header-color;
$subtitle-strong-color: $theme-header-color;
// Logo
$loadingLogoFill: $theme-content-color;
$loadingLogoStroke: $loadingLogoFill;
// Nav
$logo-color: $theme-content-color;
$logo-shadow: setShadow($soft-bg, $useShadows);
$nav-link-color: $theme-nav-color;
$nav-link-decoraction: $theme-link-color;
$nav-link-hover: $theme-nav-active-color;
$nav-link-bg-hover: $mobile-nav-hover-bg;
// Contact
$input-bdr: rgba($theme-link-color, 0.3);
$input-color: $theme-content-color;
$submit-btn-bg: $theme-link-color;
$submit-btn-color: $white;
$submit-btn-over-bg: $theme-link-active-color;
$submit-btn-over-color: #1A3C40;
$reset-btn-over-bg: rgba($theme-link-color, 0.3);
$reset-btn-over-color: $theme-content-color;
$error-bg: $theme-content-color;
$error-color: $theme-content-color;
$input-icon-color: $theme-link-color;
$buzz-off-color: $theme-content-color;
$buzz-off-border: $theme-content-color;
$required-opacity: 0.8;
// Resume
$acc-content-bdr: $input-bdr;
$acc-content-bg: $soft-bg;
$acc-content-color: $theme-content-color;
$acc-head-bg: transparent; //$section-head-bg-color;
$acc-head-color: $theme-header-color;
$acc-head-sel-color: $theme-link-active-color;
$ref-author-color: $theme-content-subtext-color;
$ref-title-color: $theme-content-subtext-color;
$ref-shadow-color: setShadow(darken($acc-content-bg, 50), $useShadows);
$ref-date-color: $theme-content-subtext-color;
$ref-quote-color: $theme-content-subtext-color;
$ref-divider-color: $theme-content-subtext-color;
$edu-item-details: rgba($acc-content-color, 0.75);
$job-date-bg: rgba($theme-link-color, 0.3);
$job-title-shadow: setShadow($job-date-bg, $useShadows);
$job-employer-color: $ref-author-color;
$job-employer-shadow-color: setShadow($ref-shadow-color, $useShadows);
$job-address-color: $ref-title-color;
$provider-over-color: $theme-link-active-color;
$skills-color: $theme-content-subtext-color;
// TLDR
$social-icon-color: $theme-content-color;
$social-icon-active-color: $white;
$social-icon-bg: $soft-bg;
$tip-text: $theme-content-color;
$tip-bg: $job-date-bg;
// Touch
$touch-bg: rgba($bg-bottom, 0.98);
$touch-divider: rgba(255, 255, 255, 0.2);
