////////////////////////////////////////////////
// SLATE
////////////////////////////////////////////////
$grey-lighter: #98a4ad;
$grey-light:   #7A8288;
$grey:         #52575C;
$grey-dark:    #3A3F44;
$grey-darker:  #272B30;

$orange: #f89406;
$green: #62c462;
$blue: #5bc0de;
$red: #ee5f5b;

$primary: $grey !default;

$dark: darken($grey-darker, 3);

$border: $grey;

$size-7: 0.85rem;

$body-background-color: $grey-darker;
$background: $grey-dark;
$button-background-color: $background;
$button-border-color: lighten($button-background-color, 15);

$title-color: #aaa;
$subtitle-color: $grey-light;
$subtitle-strong-color: $grey-light;

$text: #aaa;
$text-light: lighten($text, 10);
$text-strong: darken($text, 5);

$link: #fafafa;
$link-invert: $grey;
$link-hover: lighten($link, 5);
$link-focus: darken($link, 5);
$link-active: darken($link, 15);
$link-focus-border: darken($link, 5);
$link-active-border: $link-focus-border;

$button-color: #fafafa;

$input-hover-color: $grey-light;
$input-disabled-background-color: $grey-lighter;
$input-disabled-border: $grey-lighter;
$input-arrow: $primary;

$table-color: $text;
$table-head: $grey-lighter;
$table-background-color: $grey-dark;
$table-cell-border: 1px solid $grey;

$table-row-hover-background-color: $grey-darker;
$table-striped-row-even-background-color: $grey-darker;
$table-striped-row-even-hover-background-color: lighten($grey-darker, 4);

$pagination-border-color: $border;

$nav-height: 4rem;

$navbar-height: 4rem;
$navbar-background-color: $background;
$navbar-item-color: rgba($link, 0.5);
$navbar-item-hover-color: $link;
$navbar-item-active-color: $link;
$navbar-item-hover-background-color: darken($grey-dark, 5);
$navbar-item-active-background-color: darken($grey-dark, 5);
$navbar-dropdown-background-color: $background;
$navbar-dropdown-item-hover-color: $link;
$navbar-dropdown-item-active-color: $link;
$navbar-dropdown-item-hover-background-color: darken($grey-dark, 5);
$navbar-dropdown-item-active-background-color: darken($grey-dark, 5);

$dropdown-content-background-color: $background;
$dropdown-item-color: $text;
