html {
    overflow: hidden;
}

body {
    background: url($bg-image) $bg-arrangement;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 0;
    height: 100vh;
    width: 100vw;
    font-family: $body-fonts;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(to top, $bg-bottom 0%, $bg-top 100%);
        transition: transform 0.7s cubic-bezier(0.47, 0.04, 0.22, 0.92), height 0.2s ease, width 0.2s ease;
        opacity: 0.95;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-fonts;
}

.title {
    color: $title-color;
    font-weight: normal;
}

.box {
    color: $main-text-color;
}

a:not(.icon) {
    border-bottom: 1px dotted $link-color;

    &,
    &:hover,
    &:active {
        color: $link-color;
    }

    &:hover,
    &:active {
        color: $link-active-color;
        border-bottom: 2px solid $link-color;
        padding-bottom: 2px;
        @include transition(all 0.25s ease);
    }
}

#app {

    .footer,
    section,
    nav.navbar,
    nav.navbar .button {
        background-color: transparent;
    }
}

.footer {
    padding: 0 1.5rem 1rem;
    font-size: 0.7rem;
    opacity: 0.5;
}

.content-wrapper {
    position: absolute;
    top: 65px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;

    @include touch {
        top: 95px;

        >section {
            padding-top: 0;
        }
    }

    &.open,
    >section.open {
        @include touch {
            opacity: 0;
            @include transition(opacity 0.5s);
        }
    }
}

.section {
    @include transition(opacity 2.5s);
    opacity: 1;
    background-color: transparent;
    padding-bottom: 0;
}

.box {
    box-shadow: none;
    background-color: $soft-bg;
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
    color: $input-icon-color;
}

.noAnimation {
    @include animation(none);
}

.noTransition {
    @include transition(none);
}

.fade-enter-done {
    @include animation('fadein .5s ease-in');
}

.fade-leave-active,
.fade-exit-active {
    display: none;
}

.fade-enter {
    opacity: 0;
}

@include keyframes(fadein) {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@include keyframes(fadeout) {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

// Icon specific styling
@each $label,
$map in $icons {
    $iconColor: map-get($map, color);
    $iconShadow: setShadow(darken($iconColor, 10), $useShadows);

    a.icon-menu-#{$label} {
        &:before {
            font-family: map-get($map, font);
            content: map-get($map, icon);
            color: $social-icon-color;
            margin: 0 10px 0 0;
        }
    }
}

.icon-menu-code-school,
.icon-code-school,
.icon-menu-pluralsight,
.icon-pluralsight {
    &:before {
        font-weight: 900;
    }
}

.social-networks li a {

    &.icon-menu-code-school,
    &.icon-code-school,
    &.icon-menu-pluralsight,
    &.icon-pluralsight {
        font-weight: 900;
    }
}
