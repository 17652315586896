// Logo Loader Animation
@keyframes draw {
    0% {
        stroke-dashoffset: -2800;
        fill-opacity: 0;
    }

    80% {
        stroke-dashoffset: 0;
        fill-opacity: 0;
    }

    100% {
        stroke-dashoffset: 0;
        fill-opacity: 1;
    }
}

@keyframes logofill {
    0% {
        fill-opacity: 0;
    }

    100% {
        fill-opacity: 1;
    }
}

#loadingLogo {
    position: relative;
    z-index: 1000;

    path {
        fill: $loadingLogoFill;
        stroke: $loadingLogoFill;
        animation: draw 4s;
    }
}

.loading {
    position: absolute;
    top: calc(50% - 200px);
    left: calc(50% - 100px);
    width: 200px;
    margin: 0 auto;
}

// Transitions
.showNav,
.showView {
    @include transition(opacity .5s);
    opacity: 1;
}

.hideNav,
.hideView {
    opacity: 0;
}
